<template>
    <h3 class="card-title">
        {{ title }}
    </h3>
    <div class="card-toolbar" v-if="customerBack || route_name !== 'customer-add-detail'">
        <button
            class="btn btn-sm btn-flex btn-info btn-active-info fw-bolder"
            @click="goBack"
        >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                <i class="far fa-arrow-alt-circle-left"></i>
            </span>
            Back
        </button>
    </div>
</template>

<script setup>
import { inject, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useRoleBasedConditions } from '@/store/composable/User'
import { useStore } from 'vuex'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { customerBack } = useRoleBasedConditions()

const { title, route_name, driver_id, leadsAndTasks } = inject('customers')


const customer = reactive(store.state.Customer.customer)


function goBack() {
    if(['customer-add-detail', 'customer-add-bike-detail'].includes(route_name) || leadsAndTasks) router.push({ name: 'customer-list' }).then(() => { router.go(0) })
    else router.push({ name: 'customer-details', params: { customerId: customer.customer_id } }).then(() => { router.go(0) })

}
</script>

